$('html').attr('style', '');

$(document).ready(function () {

  ///FAQ
  $(".accordion-header").click(function () {
    $(this).toggleClass("active");
    $(this).next().toggleClass("active");
  });

  ///SpMenu
  const spMenu = document.querySelectorAll('.SpMenuButton');

  for (let i = 0; i < spMenu.length; i++) {
    let spMenuEach = spMenu[i];
    let content = spMenuEach.nextElementSibling;
    spMenuEach.addEventListener('click', () => {
      spMenuEach.classList.toggle('SpMenuButton--open');
    });
  }


  ///Contact
  jQuery(function ($) {
    var area = ($('.mw_wp_form_confirm .bifurcation-area').html());
    if (area.indexOf('資料請求') != -1) {
      $('.open-area').css('display', 'block');
    }
  });

  ///Process
  const paginations = document.querySelectorAll(".ProcessNavigationList__dot");
  paginations.forEach(pagination => {
    pagination.addEventListener("click", e => {
      e.preventDefault();
      const targetId = e.target.hash;
      const target = document.querySelector(targetId);
      target.scrollIntoView({ behavior: "smooth" });
    });
  });

  // Intersection Observer
  const sections = document.querySelectorAll(".Process");
  const observerRoot = document.querySelector(".ProcessGroup");
  const options = {
    root: observerRoot,
    rootMargin: "-50% 0px",
    threshold: 0
  };
  const observer = new IntersectionObserver(doWhenIntersect, options);
  sections.forEach(section => {
    observer.observe(section);
  });

  /**
   * 交差したときに呼び出す関数
   * @param entries - IntersectionObserverEntry IntersectionObserverが交差したときに渡されるオブジェクトです。
   */
  function doWhenIntersect(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        activatePagination(entry.target);
      }
    });
  }

  /**
   * ページネーションの大きさを変える関数
   * @param element - HTMLElement 現在表示中のスライドのHTML要素を引数に取ります。
   */
  function activatePagination(element) {
    const currentActiveIndex = document.querySelector("#pagination .active");
    if (currentActiveIndex !== null) {
      currentActiveIndex.classList.remove("ProcessNavigationList__dot--active");
    }
    // const newActiveIndex = document.querySelector('a[href="#${element.id}"]');
    const newActiveIndex = document.querySelector(".ProcessNavigationList__dot");
    newActiveIndex.classList.add("ProcessNavigationList__dot--active");
  }
});

$(window).on('load scroll resize', function () {

  //SHOW
  $('.js--show').each(function (index) {
    var elemPos = $(this).offset().top;
    var scroll = $(window).scrollTop();
    var windowHeight = $(window).height();
    var showPosition = elemPos - windowHeight + (windowHeight / 1.5);
    if (scroll > showPosition) {
      $(this).addClass('is--show');
      $(this).addClass('is--addTransition');
      $('.ProcessNavigationList__dot.dot-' + (index + 1)).addClass('is--active');
    } else if (scroll < showPosition + (windowHeight * 1)) {
      $(this).removeClass('is--show');
      $('.ProcessNavigationList__dot.dot-' + (index + 1)).removeClass('is--active');
    }
    // console.log('show v3' + elemPos);
  });

  addClassOnScroll(".Process__fixed", "is--Fixed");
});

function addClassOnScroll(element, className) {
  var windowHeight = $(window).height();
  var scroll = $(window).scrollTop();
  var classPos = $('.ProcessGroup').offset().top;
  var classPos2 = $('.ContactBanner').offset().top;
  var startTriggerPos = classPos - 80;
  var endTriggerPos = classPos2 - windowHeight;

  if (endTriggerPos >= scroll && scroll >= startTriggerPos) {
    $(element).addClass(className);
    $('.ProcessNavigation').addClass('is--show');
    console.log('active');
  } else if (scroll > endTriggerPos) {
    $(element).removeClass(className);
    $('.ProcessNavigation').removeClass('is--show');
    console.log('done');
  } else {
    $(element).removeClass(className);
    $('.ProcessNavigation').removeClass('is--show');
  }
  // console.log('addClassOnScroll'); 
}